import './App.css';
import { Analytics } from '@vercel/analytics/react';
import {useState} from "react";

function App() {
    const [user, setUser] = useState('Username');
    const [cacaType, setCacaType] = useState('CacaType');
    const [userForm, setUserForm] = useState(true)
    const [isFlipped, setIsFlipped] = useState(false);

    const flipCard = () => {
        setIsFlipped(!isFlipped);
    }

    const handleChange = (e) => {
        setUser(e.target.value)
    }

    const handleSelect = (e) => {
        setCacaType(e.target.value)
    }

    const disableForm = () => {
        if (user !== 'Username' && cacaType !== '') // Vérifie que cacaType a une valeur choisie
        {
            setUserForm(false);
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) { // Firefox
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
                document.documentElement.msRequestFullscreen();
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Empêche le comportement par défaut de la touche Entrée
            disableForm();
        }
    }

    return (
        <div>
            <Analytics/>
            {userForm ? (
                <div className={"bg-neutral-100 w-screen h-screen text-black flex"}>
                    <div className="absolute flex h-screen w-screen max-sm:hidden">
                        <div className="m-auto w-2/3 flex justify-around">
                            <div></div>
                            <img src="svg/ellipse.svg" width={250} className=""/>
                        </div>
                    </div>
                    <div onKeyDown={handleKeyDown} className="flex bg-white bg-opacity-50 w-2/3 m-auto backdrop-blur-3xl shadow-2xl max-sm:shadow-none sm:rounded-2xl h-3/4 p-2 max-sm:h-screen max-sm:w-screen max-sm:p-0 max-sm:flex-col max-sm:m-0 max-sm:rounded-none">
                        <div className={`card ${isFlipped ? 'flipped' : ''} flex h-full w-1/2 max-sm:w-full max-sm:rounded-none `} onClick={flipCard}>
                            <div className="card-face front flex h-full bg-linear-red w-full rounded-2xl max-sm:rounded-none">
                                <img className="m-auto" src="svg/caca.svg" width={150} height={150}/>
                            </div>
                            <div
                                className="card-face back  flex h-full bg-linear-red w-full rounded-2xl flex-col text-white justify-center p-8 max-sm:rounded-none">
                                <div className="text-2xl font-bold">
                                    Crédits :
                                </div>
                                <div className="h-16 max-sm:h-0"></div>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>
                                            Création :
                                        </td>
                                        <td>
                                            Léo & Arthur
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="h-16 max-sm:h-0"></div>
                            </div>
                        </div>
                        <div className="w-1/2 h-full flex justify-between max-sm:w-full max-sm:rounded-xl">
                            <div className="w-2/3 max-sm:mx-auto sm:m-auto h-1/2  flex justify-between flex-col max-sm:w-3/4 max-sm:h-3/4">
                                <div>
                                    <div className="font-bold text-4xl max-sm:hidden">
                                        Bienvenue !
                                    </div>
                                    <div className="mt-2 max-sm:hidden">
                                        Pour cacater vos amis
                                        entrer les infos suivantes !
                                    </div>
                                </div>
                                <input onChange={handleChange} className="w-full border-amber-900 border-2 rounded p-2"
                                       type="text" placeholder="Votre nom"/>
                                <select onChange={handleSelect} required defaultValue=""
                                        className="w-full border-amber-900 border-2 rounded p-2">
                                    <option value="" disabled hidden>Votre forme de caca préféré</option>
                                    <option value="caca en petites boules dures">Caca en petites boules dures</option>
                                    <option value="caca en forme de saucisse grumeleuse">Caca en forme de saucisse grumeleuse</option>
                                    <option value="caca en forme de saucisse avec des fissures">Caca en forme de saucisse avec des fissures</option>
                                    <option value="caca en forme de saucisse avec des fissures">Caca lisse et allongé</option>
                                    <option value="caca en morceaux mous, bien formés">Caca en morceaux mous, bien formés</option>
                                    <option value="caca mou et pâteu">Caca mou et pâteux</option>
                                    <option value="caca liquide">Caca liquide</option>
                                </select>
                                <div onClick={disableForm}
                                     className="bg-amber-900 rounded-full text-white text-center p-2 shadow-xl cursor-pointer hover:bg-amber-900 duration-500 max-sm:p-6 text-xl font-medium">
                                    Cacater
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="absolute sm:hidden flex h-screen w-screen pointer-events-none">
                        <div className="m-auto bg-neutral-50 w-full h-16 rounded-full">
                        </div>
                    </div>
                </div>
            ) : null}
            {userForm === false ? (
                <div className="font-light ml-20 max-sm:mx-6 flex justify-between h-screen">
                    <div className="w-2/3 max-sm:w-full flex justify-around flex-col">
                        <div className="text-8xl mt-20">
                            :(
                        </div>
                        <div className="text-4xl max-sm:text-xl">
                            <div className="font-bold mt-16">
                                Vous avez été cacaté !
                            </div>
                            <div className="mt-6 leading-normal">
                                {user} a décidé de vous cacater, car vous avez oublié de verrouiller votre PC lors de votre absence.

                            </div>
                            <div className="mt-16">
                                200% completé
                            </div>
                        </div>
                        <div className="mt-20 flex">
                            <img className="max-sm:hidden h-fit" src="svg/qr.svg" width={152} height={152}/>
                            <div className="text-2xl max-sm:text-lg flex flex-col justify-between ml-6 max-sm:ml-0">
                                <div className="max-sm:hidden">
                                    Pour vous caca-éduquer, scannez le QR code ou cliquez <a className="font-bold underline" href="https://www.stormshield.com/fr/actus/sensibilisation-une-culture-cybersecurite-efficace/">
                                    ici</a>. Et n’oublie pas, {user} attend que vous démontiez les chiottes du bureau avec votre <span className="font-bold">{cacaType}</span>.
                                </div>
                                <div className="sm:hidden">
                                    Et n’oubliez pas, {user} attend que vous démontiez les chiottes du bureau avec votre <span className="font-bold">{cacaType}</span>
                                </div>
                                <div className="max-sm:hidden">
                                    Error code : CACATAGE
                                </div>
                                <div className="h-32"></div>
                            </div>
                        </div>
                    </div>
                    <div className="my-auto mr-48 max-sm:mr-0 max-sm:my-6 max-sm:w-8">
                        <img src="svg/caca.svg" width={150} height={150}/>
                    </div>
                    <div className="absolute right-16 bottom-8 text-xl max-sm:w-full max-sm:right-0 max-sm:bottom-0 max-sm:text-center max-sm:inline-block max-sm:align-bottom max-sm:bottom-6">#Cacatage</div>
                </div>
            ) : null}

        </div>
    );
}

export default App;
